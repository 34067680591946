import {get, post, download} from "./index"

/**
 * 保存邮箱
 * @param data
 * @returns {Promise<any>}
 */
export const saveEmail = data => {
    return post("/email/saveEmail", data).then(res => res);
}

/**
 * 获取邮箱
 * @param data
 * @returns {Promise<unknown>}
 */
export const getEmail =()=> {
    return get("/email/getEmail").then(res => res);
}
